'use client'
import { LoggerEvents, appInsights } from '@bw/core'
import { Button } from '@bw/ui'
import { useEffect } from 'react'
import { publicConfig } from '../src/config'
import styles from './not-found.module.scss'

export default function Error({
	error,
}: {
	error: Error & { digest?: string }
}) {
	useEffect(() => {
		appInsights(publicConfig.appInsightsKey)?.trackEvent({
			name: LoggerEvents.UncaughtExceptionWebApp,
		}, {
			error,
			digest: error.digest,
		})
	}, [error])
	return (
		<>
			<div className="container container--max">
				<div className={styles.notFound}>
					<h1>
						You’ve wandered off the policy path!
					</h1>
					<p>
						Looks like you&apos;ve taken a detour into the digital wilderness.
						Don&apos;t worry, Brightway&apos;s here to help you find insurance
						policies that are more straightforward than your browser&apos;s
						current confusion. Let&apos;s get you back on the path to the right
						policy!
					</p>
					<Button
						title="Back Home"
						backgroundColor="brightYellow"
						textColor="deepBlue"
						borderColor="none"
						href="/"
					/>
				</div>
			</div>
		</>
	);
}

